<template>
    <div class="multiSelectWithSearch">
        <div class="inputContainer">
            <input type="text" class="multiSelectWithSearch__placeholder" :placeholder="placeholder" v-model="inputValue">
        </div>

        <div @click="toggleSelectAll" class="multiSelectWithSearch__answer">
            <div class="multiSelectWithSearch__answer__checkboxBlock"
                :class="{ 'selected': value.length === answers.length }">
                <img src="/img/default/selected.svg" alt="">
            </div>
            <div class="multiSelectWithSearch__answer__text">Выбрать все</div>
        </div>

        <div v-for="ans in showingAnswers" @click="toggleValue(ans.value)" :title="ans.text" class="multiSelectWithSearch__answer">
            <div class="multiSelectWithSearch__answer__checkboxBlock" :class="{ 'selected': value.includes(ans.value) }">
                <img src="/img/default/selected.svg" alt="">
            </div>
            <div class="multiSelectWithSearch__answer__text">{{ ans.text }}</div>
        </div>
    </div>
</template>

<style scoped lang="scss" src="./style.scss"></style>

<script src="./script.js"></script>
