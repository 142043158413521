<template>
    <header>
        <div class="header">
            <div @click="$router.push('/')" class="logo">
                <img src="/img/default/sportLogo.png" alt="">
            </div>

            <nav :class="headerTextColor" class="navigation">
                <div class="headerLink" @click="anchorCard()">Карта мероприятий</div>
                <div class="headerLink" @click="$router.push('/becomeCoach')">Стать тренером</div>
                <div class="headerLink" @click="$router.push('/eventCalendar')">Календарь</div>
                <div v-if="[2, 5].includes($store.state?.userProfileData?.roleId)" class="headerLink" @click="$router.push('/trainerCabinet')">Кабинет тренера</div>
                <div v-if="[3, 4, 5].includes($store.state?.userProfileData?.roleId)" class="headerLink" @click="$router.push('/adminPanel')">Панель администратора</div>
                <!-- <div class="headerLink" @click="$router.push('/becomeCoach')">Тренеры</div> -->
                <!-- <div class="headerLink" @click="$router.push('/userProfile')">Личный кабинет</div> -->
            </nav>

            <router-link v-if="!$store.state.userIsAuthorized && $route.fullPath !== '/auth'" to="/auth" class="authBtn secondary-button">
                <!-- <img class="authBtn__not_hover"  alt=""> -->
                <!-- <img class="authBtn__hover" src="/img/default/enteranceBtn-hover.svg" alt=""> -->
                Войти
            </router-link>

            <div v-else-if="$store.state.userIsAuthorized" class="header__userBlock">
                <div class="header__userBlock__circle" @click="openMenuUser()" @click.stop>
                    <img
                        :src="$store?.state?.userProfileData?.photo != null ? $store?.state?.userProfileData?.photo : '/img/default/user.svg'" />
                </div>
                
                <div class="header__userBlock__menu" v-show="$store?.state?.showMenuUser">
                    <div class="header__userBlock__menu__topBlock">
                        <span class="header__userBlock__menu__topBlock__fio">
                            {{ $store?.state?.userProfileData?.fio }}
                        </span>
                        <span class="header__userBlock__menu__topBlock__email">
                            {{ $store?.state?.userProfileData?.email }}
                        </span>
                    </div>
                    <div class="header__userBlock__menu__botBlock">
                        <div class="header__userBlock__menu__botBlock__item" @click="$router.push('/userProfile')">
                            <img src="/img/default/personalCabinet.svg" alt="">
                            <span class="header__userBlock__menu__botBlock__item__title">Личный кабинет</span>
                        </div>

                        <div v-if="[2, 5].includes($store.state?.userProfileData?.roleId)" class="header__userBlock__menu__botBlock__item" @click="$router.push('/trainerEventHistory')">
                            <img src="/img/default/trainerEventHistory.svg" alt="">
                            <span class="header__userBlock__menu__botBlock__item__title">История мероприятий</span>
                        </div>

                        <span class="header__userBlock__menu__botBlock__row"></span>

                        <div class="header__userBlock__menu__botBlock__item" @click="logout()">
                            <img src="/img/default/exit.svg" alt="">
                            <span class="header__userBlock__menu__botBlock__item__title">Выход</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobileBurger">
                <!-- <img @click="openMobileBurgerMenu" :src="`/img/default/header-burger-${headerTextColor}.svg`" alt=""> -->
                <span @click="openMobileBurgerMenu" :class="headerTextColor" class="mobileBurger__burgerStick"></span>
            </div>
        </div>
    </header>
</template>

<style scoped lang="scss" src="./style.scss"></style>

<script src="./script.js"></script>
