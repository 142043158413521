export default {
    name: "myMultiSelect",

    props: {
        placeholder: {
            type: String,
            default: "Выберите вариант"
        },

        value: {
            type: String,
            require: true,
        },

        tabName: {
            type: String,
            default: "",
        },

        answers: {
            type: Array,
            default: [],
        },
    },

    data() {
        return {
            opened: false,

            localTimeoutVariable: undefined,
            isEmitSlectTagEvent: false,

            selectAll: false,
        }
    },

    computed: {
        showingAnswers() {
            let res = this.value.map(el => this.answers.find(ans => ans.value === el).text).join(", ");
            return res;
        },
    },

    watch: {
        answers: {
            handler() {
                if (this.selectAll) {
                    this.toggleSelectAll();
                }
            },
            deep: true,
        },
    },

    methods: {
        toggleValue(value) {
            let res;
            if (this.value.includes(value)) res = this.value.filter(el => el !== value);
            else res = [...this.value, value];

            this.$emit("update:value", res);
        },

        toggleSelectAll() {
            if (this.value.length === this.answers.length) {
                this.$emit("update:value", []);
                this.selectAll = false;
            }
            else {
                this.$emit("update:value", this.answers.map(el => el.value));
                this.selectAll = true;
            }

        },

        toggleAnswers() {
            this.opened = !this.opened;
        },

        emitUploadNextPage() {
            if (this.isEmitSlectTagEvent === false) {
                this.isEmitSlectTagEvent = true;
                clearTimeout(this.localTimeoutVariable);

                let maxScrollHeight = this.$refs.options.scrollHeight;
                let curScrollValue = this.$refs.options.scrollTop + this.$refs.options.offsetHeight;

                if ((curScrollValue / maxScrollHeight) > .9) {
                    this.$emit("selectTagEvent", {
                        handlerName: "uploadNextPage",
                        arguments: {
                            tabName: this.tabName,
                        },
                    });
                }

                this.localTimeoutVariable = setTimeout(() => {
                    this.isEmitSlectTagEvent = false;
                }, 120)
            }
        },
    },

    mounted() {
        this.$refs.options.addEventListener("scroll", this.emitUploadNextPage);
    },

    beforeUnmount() {
        this.$refs.options.removeEventListener("scroll", this.emitUploadNextPage);
    },
}
