<template>
    <div v-if="show" @click="hide" class="wraper">
        <div @click.stop class="modalWindow">
            <div @click="hide" class="modalWindow__closeBtn">
                <img src="/img/modalWindow/cross.svg">

            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script src="./script.js"></script>

<style scoped lang="scss" src="./style.scss"></style>
