import axios from "axios";
import Cookies from "js-cookie";
import authMixin from "./authMixin";

export default {
    mixins: [
        authMixin,
    ],

    methods: {
        async axiosRequest({ method = "get", url, data = undefined, checkToken = true }) {
            let _url = this.$store.state.backendURL + url;

            let requestConf = {
                method,
                url: _url,
                data,
                headers: {
                    Authorization: checkToken ? Cookies.get("Auth") ?? "" : "",
                },
            };

            return axios(requestConf).catch(er => {
                if (er.response.status === 403) {
                    this.logoutWithoutSuccess('/auth');
                }

                if (er.response.status === 429) {
                    let timeoutValue = Number(er.response.headers["x-rate-limit-retry-after-milliseconds"]);
                    timeoutValue = Math.ceil(timeoutValue / 100) * 100;

                    return new Promise((resolve) => setTimeout(resolve, timeoutValue)).then(res => {
                        return this.axiosRequest(arguments[0]);
                    });
                }

                if (er.response.status !== 429) {
                    this.$toast.error(er.response.data);
                    throw er;
                }
            });
        },
    },
}
