import Cookies from "js-cookie"

export default {
    methods: {
        authorization(token) {
            Cookies.set("Auth", token);

            let requestConf = {
                method: "get",
                url: "/user/get/profile",
            };

            this.axiosRequest(requestConf).then(res => {
                this.$store.commit("setUserProfileData", res.data);
                this.$store.commit("setIsAuthorized", true);
            }).catch(er => {
                console.log(er);
            });
        },

        // logout(targetPage = "/") {
        //     Cookies.remove("Auth");

        //     this.$store.commit("setIsAuthorized", false);
        //     this.$router.push(targetPage);
        // },

        logoutWithoutSuccess(targetPage) {
            Cookies.remove("Auth");

            this.$store.commit("setUserIsAuthorized", false);
            this.$store.commit("setUserProfileData", undefined);
            this.$router.push(targetPage);
        },

        logout(targetPage = "/") {
            this.logoutWithoutSuccess(targetPage);
            this.$toast.success("Вы успешно вышли из сервиса");
        },

        parseJwt() {
            let token = Cookies.get("Auth").split(" ")[1];

            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        },
    },
}
