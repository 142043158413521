export default {
    name: "modalWindow",

    props: {
        show: {
            type: Boolean,
            require: true,
        },
    },

    methods: {
        hide() {
            this.$emit("update:show", false);
        },
    },
}