import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Главная',
    component: () => import("@/views/mainPage/mainPage.vue"),
  },
  {
    path: '/becomeCoach',
    name: 'Стать тренером',
    component: () => import("@/views/becomeCoach/becomeCoach.vue"),
  },
  {
    path: '/prizesPage',
    name: 'Призы',
    component: () => import("@/views/prizesPage/prizesPage.vue"),
  },
  {
    path: '/communitiesPage',
    name: 'Сообщества',
    component: () => import("@/views/communitiesPage/communitiesPage.vue"),
  },
  {
    path: '/eventCalendar',
    name: 'Календарь мероприятий',
    component: () => import("@/views/eventCalendar/eventCalendar.vue"),
  },
  {
    path: '/auth',
    name: 'Авторизация',
    component: () => import("@/views/authPage/authPage.vue"),
  },
  {
    path: '/reg',
    name: 'Регистрация',
    component: () => import("@/views/regPage/regPage.vue"),
  },
  {
    path: '/fogotPassword',
    name: 'Забыли пароль',
    component: () => import("@/views/fogotPassword/fogotPassword.vue"),
  },
  {
    path: '/regConfirmation',
    name: '',
    component: () => import("@/views/regConfirmation/regConfirmation.vue"),
  },
  {
    path: '/adminPanel',
    name: 'Панель администратора',
    component: () => import("@/views/adminPanel/adminPanel.vue"),
  },
  {
    path: '/coaches',
    name: 'Тренеры',
    component: () => import("@/views/coaches/coaches.vue"),
  },
  {
    path: '/userProfile',
    name: 'Профиль',
    component: () => import("@/views/userProfile/userProfile.vue"),
    children: [
      {
        path: "/userProfile",
        name: 'Профиль',
        component: () => import("@/views/userProfile/userProfileTrainings/userProfileTrainings.vue"),
      },
      {
        path: "/userProfile/tables",
        name: 'Таблица тренировок',
        component: () => import("@/views/userProfile/userProfileTable/userProfileTable.vue"),
      },
      {
        path: "/userProfile/edit",
        name: 'Редактирование профиля',
        component: () => import("@/views/userProfile/userProfileEdit/userProfileEdit.vue"),
      },
      {
        path: "/userProfile/points",
        name: 'Баллы',
        component: () => import("@/views/userProfile/userProfilePoints/userProfilePoints.vue"),
      },
      {
        path: '/userProfile/:pathMatch(.*)*',
        redirect: '/userProfile',
      },
    ],
  },
  {
    path: '/trainerCabinet',
    name: 'Кабинет тренера',
    component: () => import("@/views/trainerCabinet/trainerCabinet.vue"),
  },
  {
    path: '/addUserManually',
    name: 'Ручное добавление пользователя',
    component: () => import("@/views/addUserManually/addUserManually.vue"),
  },
  {
    path: '/addNotRegisteredUser',
    name: 'Добавление незарегистрированного пользователя',
    component: () => import("@/views/addNotRegisteredUser/addNotRegisteredUser.vue"),
  },
  {
    path: '/yandex',
    name: 'Яндекс oAuth авторизация',
    component: () => import("@/views/yandex/yandex.vue"),
  },
  {
    path: '/vk',
    name: 'Вк oAuth авторизация',
    component: () => import("@/views/vkAuth/vkAuth.vue"),
  },
  {
    path: '/trainerEventHistory',
    name: 'История мероприятий тренера',
    component: () => import("@/views/trainerEventHistory/trainerEventHistory.vue"),
  },
  {
    path: '/vote',
    name: 'Голосование',
    component: () => import("@/views/votePage/votePage.vue"),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
});
router.beforeEach((to) => {
  document.title = to.name;
});

export default router
