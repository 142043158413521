export default {
    name: "scrollBtn",

    props: {
        scrollValue: {
            type: Number,
            require: true,
        },
    },

    methods: {
        scrollEvent() {
            window.scrollTo({ top: this.scrollValue, behavior: 'smooth' })
        },
    },
}