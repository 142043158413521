<template>
  <v-app>
    <v-main @click="closeUserMenu">
      <myHeader v-if="!mobileBurgerMenuIsOpen" @openMobileBurgerMenu="openMobileBurgerMenu" />
      <Transition name="burgerMenu">
        <mobileBurgerMenu v-if="mobileBurgerMenuIsOpen" @closeMobileBurgerMenu="closeMobileBurgerMenu"></mobileBurgerMenu>
      </Transition>
      <router-view v-if="!mobileBurgerMenuIsOpen" />
      <myFooter
        v-if="!mobileBurgerMenuIsOpen && !['/trainerCabinet', '/addUserManually', '/reg', '/auth', '/fogotPassword', '/userProfile', '/userProfile/edit', '/userProfile/tables', '/addNotRegisteredUser', '/userProfile/tables?past=true', '/trainerEventHistory', '/trainerEventHistory?past=true'].includes($route.fullPath)" />
    </v-main>
  </v-app>
</template>

<style>
html {
  overflow-y: auto !important;
}

.burgerMenu-enter-active,
.burgerMenu-leave-active {
  transition: all 0.5s ease;
}

.burgerMenu-enter-from,
.burgerMenu-leave-to {
  opacity: 0;
}

.burgerMenu-enter-from,
.burgerMenu-leave-to {
  transform: translateX(100px);
}
</style>

<script>
import myHeader from "@/components/myHeader/myHeader.vue";
import myFooter from "@/components/myFooter/myFooter.vue";
import mobileBurgerMenu from "@/components/mobileBurgerMenu/mobileBurgerMenu.vue"
import userAction from "./mixins/userAction";

export default {
  name: 'App',

  mixins: [userAction],

  components: {
    myHeader, myFooter, mobileBurgerMenu
  },
  data() {
    return {
      mobileBurgerMenuIsOpen: false,
    }
  },
  methods: {
    openMobileBurgerMenu() {
      this.mobileBurgerMenuIsOpen = true;
    },

    closeMobileBurgerMenu() {
      this.mobileBurgerMenuIsOpen = false;
    },

    closeUserMenu() {
      this.$store.commit("setShowMenuUser", false);
    }
  },
  mounted() {
    this.getUser();
  },
}
</script>
