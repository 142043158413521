export default {
    name: "myMultiSelectWithSearch",

    props: {
        placeholder: {
            type: String,
            default: "Выберите вариант"
        },

        value: {
            type: String,
            require: true,
        },

        answers: {
            type: Array,
            default: [],
        },
    },

    data() {
        return {
            opened: false,

            inputValue: '',
        }
    },

    computed: {
        showingAnswers() {  
            console.log("filter");
            let res = this.answers.filter(ans => ans.text.toUpperCase().includes(this.inputValue.toUpperCase()));
            return res;
        },
    },

    methods: {
        toggleValue(value) {
            if (this.value.includes(value)) this.$emit("update:value", this.value.filter(el => el !== value));
            else this.$emit("update:value", [...this.value, value]);
        },

        toggleSelectAll() {
            if (this.value.length === this.answers.length) this.$emit("update:value", []);
            else this.$emit("update:value", this.answers.map(el => el.value));
        },
    },
}
