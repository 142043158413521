import userAction from "@/mixins/userAction";

export default {
    mixins: [
        userAction,
    ],

    data() {
        return {
            headerTextColor: 'white',

            whiteRoutes: [
                "/",
                "/becomeCoach",
                "/prizesPage",
                "/auth",
                "/reg",
                "/fogotPassword"
            ],
        }
    },

    computed: {
        route() {
            return this.$route;
        },
    },

    watch: {
        route() {
            if (this.whiteRoutes.includes(this.route.fullPath)) {
                this.headerTextColor = 'white';
            } else {
                this.headerTextColor = 'blue';
            }
        }
    },

    methods: {
        openMobileBurgerMenu() {
            this.$emit('openMobileBurgerMenu');
        },

        anchorCard() {
            this.$router.push('/')
            setTimeout(() => {
                this.$store.commit("setAnchorCard", true);
            }, 500);
        },

        openMenuUser() {
            this.$store.commit("setShowMenuUser", !this.$store?.state?.showMenuUser);
        }
    },
    mounted() {
        if (this.whiteRoutes.includes(this.route.fullPath)) {
            this.headerTextColor = 'white';
        } else {
            this.headerTextColor = 'blue';
        }
    }
}
