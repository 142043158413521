<template>
    <div v-if="delay" class="footerContainer">
        <div class="footerContainer__generalBlock">
            <div class="footerContainer__generalBlock__projectsBlock">
                <div class="footerContainer__generalBlock__projectsBlock__item sportsSeterans">
                    <span class="footerContainer__generalBlock__projectsBlock__item__title">
                        Ветераны спорта<br />Нижнего Новгорода
                    </span>
                    <div class="footerContainer__generalBlock__projectsBlock__item__body">
                        <span class="footerContainer__generalBlock__projectsBlock__item__body__description">
                            Узнайте о выдающихся спортсменах Нижнего Новгорода.
                        </span>
                        <a href="https://veteranysporta.acgnn.ru/" target="_blank">
                            <div class="footerContainer__generalBlock__projectsBlock__item__body__btnLink">
                                <span class="footerContainer__generalBlock__projectsBlock__item__body__btnLink__text">
                                    Перейти на сайт
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
                
                <div class="footerContainer__generalBlock__projectsBlock__item feedback">
                    <span class="footerContainer__generalBlock__projectsBlock__item__title">
                        Обратная связь
                    </span>
                    <div class="footerContainer__generalBlock__projectsBlock__item__body">
                        <span class="footerContainer__generalBlock__projectsBlock__item__body__description">
                            Сканируйте QR-код и оставляйте<br class="desktop" /> эмоджи и отзыв.
                        </span>
                        <a href="https://feedback.acgnn.ru/" target="_blank">
                            <div class="footerContainer__generalBlock__projectsBlock__item__body__btnLink">
                                <span class="footerContainer__generalBlock__projectsBlock__item__body__btnLink__text">
                                    Перейти на сайт
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="footerContainer__generalBlock__partnersBlock">
                <a href="https://admgor.nnov.ru/" target="_blank">
                    <div class="footerContainer__generalBlock__partnersBlock__item item3">
                        <img src="/img/default/footerLink_3.svg"
                            class="footerContainer__generalBlock__partnersBlock__item__img">
                        <span class="footerContainer__generalBlock__partnersBlock__item__title">
                            Администрация города<br />Нижнего Новгорода
                        </span>
                    </div>
                </a>

                <a href="https://acgnn.ru/" target="_blank">
                    <div class="footerContainer__generalBlock__partnersBlock__item item1">
                        <img src="/img/default/footerLink_1.svg"
                            class="footerContainer__generalBlock__partnersBlock__item__img">
                        <span class="footerContainer__generalBlock__partnersBlock__item__title">
                            Аналитический центр<br class="modail" /> города<br class="desktop" /> Нижнего Новгорода
                        </span>
                    </div>
                </a>

                <a href="https://vk.com/mc.gto_nn" target="_blank">
                    <div class="footerContainer__generalBlock__partnersBlock__item item4">
                        <img src="/img/default/footerLink_4.png"
                            class="footerContainer__generalBlock__partnersBlock__item__img">
                        <span class="footerContainer__generalBlock__partnersBlock__item__title">
                            МАУ МЦ Спортивных<br />объектов и ГТО
                        </span>
                    </div>
                </a>

                <a href="http://www.unn.ru/" target="_blank">
                    <div class="footerContainer__generalBlock__partnersBlock__item item2">
                        <img src="/img/default/footerLink_2.svg"
                            class="footerContainer__generalBlock__partnersBlock__item__img img2">
                        <span class="footerContainer__generalBlock__partnersBlock__item__title">
                            Нижегородский государственный<br /> университет им. Н.И.<br class="desktop" />Лобачевского
                        </span>
                    </div>
                </a>
            </div>
        </div>
        <span class="footerContainer__text">Спорт в каждый двор © 2023</span>
    </div>
</template>

<style scoped lang="scss" src="./style.scss">
</style>

<script src="./script.js"></script>
