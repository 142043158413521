import { createStore } from 'vuex'

const hostname = window.location.hostname;
let backendURL = "https://java.acgnn.ru/sport";
let backendLink = '';
if (hostname === 'localhost' || hostname === 'dev_sport.acgnn.ru' || hostname.indexOf('10.') !== -1 || hostname.indexOf('192.') !== -1) {
  backendURL = 'https://dev_java.acgnn.ru/sport';
  backendLink = 'dev_';
}

export default createStore({
  state: {
    backendURL: backendURL,
    backendLink: backendLink,

    anchorCard: false,
    showMenuUser: false,
    windowResetPassword: false,


    userToken: undefined,
    userRefreshToken: undefined,
    userProfileData: undefined,
    userIsAuthorized: false,


    // backendURL: "http://10.50.53.115:8080/sport",
    // pagesClusters: {},

    // userProfileData: undefined,

    trainerActiveTrainingId: '',
    userRegisteredForTraining: {},
  },
  getters: {
  },
  mutations: {
    setUserToken(state, value) {
      state.userToken = value
    },

    setUserRefreshToken(state, value) {
      state.userRefreshToken = value
    },

    setUserProfileData(state, value) {
      state.userProfileData = value
    },

    setUserProfilePhoto(state, value) {
      state.userProfileData.photo = value;
    },

    setUserIsAuthorized(state, value) {
      state.userIsAuthorized = value;
    },

    setAnchorCard(state, value) {
      state.anchorCard = value;
    },

    setShowMenuUser(state, value) {
      state.showMenuUser = value;
    },

    setWindowResetPassword(state, value) {
      state.windowResetPassword = value;
    },
    // createCluster(state, name) {
    //   state.pagesClusters[name] = {};
    // },

    // clearCluster(state, name) {
    //   delete state.pagesClusters[name];
    // },

    setTrainerActiveTrainingId(state, value) {
      state.trainerActiveTrainingId = value;
    },

    setUserRegisteredForTraining(state, value) {
      state.userRegisteredForTraining = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
