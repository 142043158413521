<template>
    <div class="container">
        <div class="container__header">
            <h2 class="container__header__title">Меню</h2>
            <img src="/img/default/mobile/cross.svg" alt="" @click="closeMobileBurgerMenu">
        </div>

        <div class="container__content">
            <div v-if="$store.state.userIsAuthorized" @click="goAndCloseBurger('/userProfile')" class="container__content__userCard">
                <img
                    :src="$store?.state?.userProfileData?.photo != null ? $store?.state?.userProfileData?.photo : '/img/default/user.svg'" />
                <div class="container__content__userCard__info">
                    <p class="container__content__userCard__info__fio">{{ $store?.state?.userProfileData?.fio }}</p>
                    <p class="container__content__userCard__info__email">{{ $store?.state?.userProfileData?.email }}</p>
                </div>
            </div>

            <div class="container__content__links">
                <div class="container__content__links__link" :class="{'active': this.$route.fullPath === '/'}" @click="goAndCloseBurger('/')">Главная</div>
                <div class="container__content__links__link" @click="anchhorAndCloseBurger()">Карта мероприятий</div>
                <div class="container__content__links__link" :class="{'active': this.$route.fullPath === '/becomeCoach'}" @click="goAndCloseBurger('/becomeCoach')">Стать тренером</div>
                <div class="container__content__links__link" :class="{'active': this.$route.fullPath === '/eventCalendar'}" @click="goAndCloseBurger('/eventCalendar')">Календарь</div>
                <div v-if="[2, 5].includes($store.state?.userProfileData?.roleId)"
                    class="container__content__links__link"
                    :class="{'active': this.$route.fullPath === '/trainerCabinet'}"
                    @click="goAndCloseBurger('/trainerCabinet')"
                >Кабинет тренера</div>

                <div v-if="[2, 5].includes($store.state?.userProfileData?.roleId)"
                    class="container__content__links__link"
                    :class="{'active': this.$route.fullPath === '/trainerEventHistory'}"
                    @click="goAndCloseBurger('/trainerEventHistory')"
                >История мероприятий</div>

                <div v-if="[3, 4, 5].includes($store.state?.userProfileData?.roleId)"
                    class="container__content__links__link"
                    :class="{'active': this.$route.fullPath === '/adminPanel'}"
                    @click="goAndCloseBurger('/adminPanel')"
                >Панель администратора</div>

                <button @click="goAndCloseBurger('/auth')" v-if="!$store.state.userIsAuthorized"
                    class="entrance-img-button">Войти</button>
                <button @click="logoutAndCloseBurger" v-else class="exit-img-button">Выйти</button>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss" src="./style.scss">
</style>

<script src="./script"></script>
