export default {
    data() {
        return {
            delay: false,
            timeout: {},
        }
    },

    mounted() {
        this.timeout = setTimeout(() => {
            this.delay = true;
        }, 500);
    },

    beforeUnmount() {
        clearTimeout(this.timeout);
    }
}
