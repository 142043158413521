import requestMixin from "@/mixins/requestMixin";
import Cookies from "js-cookie";

export default {
    mixins: [
        requestMixin,
    ],

    methods: {
        login(data, targetPage="") {
            if (typeof data === "object") {
                let requestConf = {
                    method: "post",
                    url: "/auth/signin",
                    data: data,
                    checkToken: false,
                };
                this.axiosRequest(requestConf).then(res => {
                    this.rememberUser(res.data);
                    if (targetPage.length > 0) {
                        this.$router.push(targetPage);
                    }
                    
                    this.$toast.success("Добро пожаловать!");
                }).catch(er => {
                    console.log(er);
                })
            } else {
                this.rememberUser(data);
                if (targetPage.length > 0) {
                    this.$router.push(targetPage);
                }
                
                this.$toast.success("Добро пожаловать!");
            }
        },

        rememberUser(JWT) {
            Cookies.set("Auth", JWT);
            this.$store.commit("setUserToken", JWT);
            this.$store.commit("setUserRefreshToken", JWT);
            this.getUser();
        },

        forgetPasword(data) {
            let requestConf = {
                method: "post",
                url: `/auth/change_password/${data}`,
            };
            this.axiosRequest(requestConf).then(res => {
                this.$store.commit("setWindowResetPassword", true)
            }).catch(er => {
                console.log(er);
                this.$store.commit("setWindowResetPassword", false)
            })
        },

        logout(targetPage = "/") {
            Cookies.remove("Auth");

            this.$store.commit("setUserIsAuthorized", false);
            this.$store.commit("setUserProfileData", undefined);
            this.$router.push(targetPage);

            this.$toast.success("Вы успешно вышли из сервиса");
        },

        getUser() {
            if (Cookies.get("Auth")?.length > 0) {
                this.$store.commit("setUserIsAuthorized", true);

                let requestConf = {
                    method: "get",
                    url: "/user/get/profile",
                };

                this.axiosRequest(requestConf).then(res => {
                    this.$store.commit("setUserProfileData", res.data);
                }).catch(er => {
                    console.log(er);
                    this.$store.commit("setUserIsAuthorized", false);
                });
            }
        },
    },
}
