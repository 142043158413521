<template>
    <div class="breadCrumbs">
        <div class="breadCrumbs__link" v-for="(link, idx) in links">
            <router-link class="breadCrumbs__link" :to="link.link">{{ link.title }}</router-link>
            <span v-if="idx < links.length - 1">&nbsp;/&nbsp;</span>
        </div>
    </div>
</template>

<style scoped lang="scss" src="./style.scss"></style>

<script src="./script.js"></script>
