import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import Toaster from "@meforma/vue-toaster";
import "./registerServiceWorker"

import UIComponents from "@/components/UI/index.js"

import bridge from '@vkontakte/vk-bridge';
bridge.send("VKWebAppInit");

// Подписывается на события, отправленные нативным клиентом
// bridge.subscribe((e) => console.log(e));

// bridge.send('VKWebAppGetUserInfo', {})
//   .then((data) => {
//     if (data.id) {
//       // Данные пользователя получены
//       console.log('данные')
//       console.log(data);
//     }
//   })
//   .catch((error) => {
//     // Ошибка
//     console.log(error);
//   });

// bridge.send('VKWebAppGetEmail')
//   .then((data) => {
//     if (data) {
//       // Электронная почта пользователя
//       console.log('почта')
//       console.log(data.email);
//     }
//   })
//   .catch((error) => {
//     // Ошибка
//     console.log(error);
//   });

loadFonts()

let app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify);
app.use(Toaster);

UIComponents.forEach(component => {
  app.component(component.name, component)
})

app.mount('#app');
