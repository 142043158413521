import userAction from "@/mixins/userAction";

export default {
    mixins: [
        userAction,
    ],
    data() {
        return {
            timeout: {},
        }
    },
    methods: {
        closeMobileBurgerMenu() {
            this.$emit('closeMobileBurgerMenu');   
        },
        logoutAndCloseBurger() {
            this.logout();
            this.closeMobileBurgerMenu();
        },
        goAndCloseBurger(path) {
            this.closeMobileBurgerMenu();
            
            this.timeout = setTimeout(() => {
                this.$router.push(path);
                clearTimeout(this.timeout);
            }, 1)
        },
        anchorCard() {
            this.$router.push('/')
            setTimeout(() => {
                this.$store.commit("setAnchorCard", true);
            }, 500);
        },
        anchhorAndCloseBurger() {
            this.closeMobileBurgerMenu();
            this.anchorCard();
        }
    },
}
